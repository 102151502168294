const plusFunction = (a, b) => {
    let r1, r2
    try {
        r1 = a.toString().split('.')[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = b.toString().split('.')[1].length
    } catch (e) {
        r2 = 0
    }
    return (Number(a.toString().replace('.', '')) * Math.pow(10, r2) + Number(b.toString().replace('.', '')) * Math.pow(10, r1)) / Math.pow(10, r1 + r2)
}

const multiplyFunctuon = (a, b) => {
    let m = 0
    let s1 = a.toString()
    let s2 = b.toString()
    try {
        m += s1.split('.')[1] ? s1.split('.')[1].length : ''
    } catch (e) {
        console.error(e)
    }
    try {
        m += s2.split('.')[1] ? s2.split('.')[1].length : ''
    } catch (e) {
        console.error(e)
    }
    return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m)
}

const divideFunctuon = (a, b) => {
    let t1 = 0
    let t2 = 0
    let r1
    let r2
    try {
        t1 = a.toString().split('.')[1].length
    } catch (e) {
        console.error(e)
    }
    try {
        t2 = b.toString().split('.')[1].length
    } catch (e) {
        console.error(e)
    }
    r1 = Number(a.toString().replace('.', ''))
    r2 = Number(b.toString().replace('.', ''))
    return (r1 / r2) * Math.pow(10, t2 - t1)
}

export default {
    /**
     * 十进制加法
     * @param  {number[]} nums 被加数
     * @return {number} 结果
     */
    plus(...nums) {
        let result = 0
        for (const i in nums) {
            const num = nums[i]
            result = plusFunction(result, num)
        }
        return result
    },
    /**
     * 十进制乘法
     * @param  {number[]} nums 被乘数
     * @return {number} 结果
     */
    multiply(...nums) {
        let result = 1
        for (const i in nums) {
            const num = nums[i]
            result = multiplyFunctuon(result, num)
        }
        return result
    },
    /**
     * 十进制除法
     * @param {number} source 被除数
     * @param {number[]} nums 除数
     * @return {number} 结果
     */
    divide(source, ...nums) {
        let result = source
        for (const i in nums) {
            if (nums[i] === 0) {
                return NaN
            }
            const num = nums[i]
            result = divideFunctuon(result, num)
        }
        return result
    },
    /**
     * 四舍五入
     * @param {number} number 原数
     * @param {number} precision 小数位数（可以为负数）
     * @return {number} 结果
     */
    halfUp(number, precision) {
        var factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    },
    /**
     * 向下取整
     * @param {number} number 原数
     * @param {number} precision 小数位数（可以为负数）
     * @return {number} 结果
     */
    floor(number, precision) {
        var factor = Math.pow(10, precision);
        return Math.floor(number * factor) / factor;
    },
}